import { put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { SET_SUBMIT_FAILED, STOP_ASYNC_VALIDATION } from 'redux-form/lib/actionTypes';

import { FETCH_BRANCH_FAILURE } from 'app/redux/branch/branch.types';
import { FETCH_CLINIC_FAILURE } from 'app/redux/clinic/clinic.types';
import { FETCH_INVOICE_DETAILS_FAILURE } from 'app/redux/invoice/invoice.types';
import { FETCH_DOCTOR_FAILURE } from 'app/redux/doctor/doctor.types';
import { FETCH_PATIENT_FAILURE } from 'app/redux/patient/patient.types';

export function* afterFailedDetailsFetch() {
  yield put(push('/404'));
}

export function* scrollModalOnSubmitFailed() {
  yield setTimeout(() => {
    const modalElements = document.querySelectorAll('.modal');

    if (!!modalElements.length) {
      modalElements.forEach((modalElement) => {
        const invalidElement = modalElement.querySelector('.is-invalid');
        if (!!invalidElement) {
          const position = invalidElement.getBoundingClientRect().top + modalElement.scrollTop;
          modalElement.scrollTo({ top: position - 200, behavior: 'smooth' });
        }
      });
    } else if (window.location.pathname.includes('/registration')) {
      const pageElement = document.querySelector('[class^=unauthorizedPage_wrapper]');
      const invalidElement = pageElement?.querySelector('.is-invalid');
      if (!!invalidElement) {
        const position = invalidElement.getBoundingClientRect().top + pageElement.scrollTop;
        pageElement.scrollTo({ top: position - 200, behavior: 'smooth' });
      }
    }
  }, 200);
}

function* commonSaga() {
  yield takeEvery(
    [
      FETCH_BRANCH_FAILURE,
      FETCH_CLINIC_FAILURE,
      FETCH_DOCTOR_FAILURE,
      FETCH_INVOICE_DETAILS_FAILURE,
      FETCH_PATIENT_FAILURE,
    ],
    afterFailedDetailsFetch,
  );
  yield takeEvery([SET_SUBMIT_FAILED, STOP_ASYNC_VALIDATION], scrollModalOnSubmitFailed);
}

export default commonSaga;
