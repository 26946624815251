import { useEffect, useState } from 'react';
import qs from 'qs';
import { useChangeLocale } from 'app/helpers/useChangeLocale/useChangeLocale';

export const useUrlLocale = () => {
  const [initialized, setInitialized] = useState(false);
  const changeLocale = useChangeLocale();

  useEffect(() => {
    if (!initialized) {
      const search = window.location.search;
      if (search) {
        const urlParams = qs.parse(search, { ignoreQueryPrefix: true });
        if (urlParams.locale && typeof urlParams.locale === 'string') {
          changeLocale(urlParams.locale);
        }
      }
      setInitialized(true);
    }
  }, [initialized, changeLocale]);
};
