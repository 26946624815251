import { useMemo } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { formattedDateAndTime, useAppIntl } from 'app/helpers';
import { ProcedureResponse } from 'app/redux/doctorAssessment/types';

interface Props {
  dataField: string;
  text: string;
  isPrintView?: boolean;
}

export const useColumns = ({ dataField, text, isPrintView }: Props) => {
  const { formatMessage } = useAppIntl();
  const timeNow = formattedDateAndTime(new Date());

  return useMemo(
    (): ColumnDescription<ProcedureResponse>[] => [
      {
        dataField,
        text: formatMessage({ id: text }),
        formatter: (_, row) => row.text && <span dangerouslySetInnerHTML={{ __html: row.text }} />,
        classes: 'w-75',
        style: { fontSize: '12px', color: 'black' },
        headerStyle: { fontSize: '12px', color: 'black' },
        hidden: isPrintView,
      },
      {
        dataField: 'date',
        text: formatMessage({ id: 'CORE.TABLE.DATE' }),
        formatter: (_, row) => (row.createdAt ? formattedDateAndTime(row.createdAt) : timeNow),
        style: { fontSize: '12px', color: 'black' },
        headerStyle: { fontSize: '12px', color: 'black' },
        hidden: isPrintView,
      },
      {
        dataField,
        text: formatMessage({ id: text }),
        formatter: (_, row, rowIndex) => (
          <div style={{ marginTop: rowIndex === 0 ? '-1.66rem' : '' }}>
            <div className="mb-2">
              <span style={{ fontSize: '12px', fontWeight: '500' }}>
                {formatMessage({ id: 'CORE.TABLE.DATE' })}:{' '}
                {row.createdAt ? formattedDateAndTime(row.createdAt) : timeNow}
              </span>
            </div>
            {row.text && <span dangerouslySetInnerHTML={{ __html: row.text }} />}
          </div>
        ),
        style: { fontSize: '9px', color: 'black' },
        headerStyle: { fontSize: '12px', color: 'black', padding: '1rem 0 1rem 0' },
        hidden: !isPrintView,
      },
    ],
    [timeNow, dataField, text, formatMessage, isPrintView],
  );
};
