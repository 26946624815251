import { useCallback, useState } from 'react';
import { change } from 'redux-form';
import isEqual from 'lodash/isEqual';
import type { QueryParams, QueryResponseWithPagination } from 'app/types';
import { ClinicPatientShortInfo } from 'app/services/patients/types';
import { PatientSearchField } from 'app/shared/patientSearch/types';
import { useAppDispatch } from 'app/helpers';
import { PatientInfoShort } from 'app/shared/patientSearch/patientShortDetails/types';
import {
  MIN_SEARCH_CLINICY_MRN_LENGTH,
  MIN_SEARCH_GOVERNMENT_ID_LENGTH,
  MIN_SEARCH_INTERNAL_FILE_NUMBER_LENGTH,
  PatientSearchFormValues,
} from 'app/shared/patientSearch/patientSearchFilters/patientSearchFilters';
import { useLazyGetClinicPatientInfoQuery } from 'app/services/patients/patients';
import { minLength, phoneNumber } from 'app/shared/validation';

export interface PatientSearchHandlers {
  data?: QueryResponseWithPagination<ClinicPatientShortInfo[]>;
  params: QueryParams | undefined;
  checkIsRowSelected: (id: string) => boolean;
  isAnySelected: boolean;
  isLoading: boolean;
  isFetching: boolean;
  selectionChanged: (id: string) => Promise<void>;
  resetSelection: () => void;
  handleSetParams: (newParams?: QueryParams) => void;
  handleFilterChange: (values: Partial<PatientSearchFormValues>) => void;
}

interface Props {
  formName: string;
  clinicId?: string;
  setPatient: (payload: PatientInfoShort) => { payload: PatientInfoShort; type: string };
  resetPatient: () => { type: string };
}

export const usePatientSearchHandlers = ({ formName, clinicId, setPatient, resetPatient }: Props) => {
  const dispatch = useAppDispatch();
  const [params, setParams] = useState<QueryParams | undefined>({ pageSize: '5' });
  const [selectedRow, setSelectedRow] = useState<string | null>(null);

  const isAnySelected = !!selectedRow;
  const [triggerFetch, { data, isLoading, isFetching }] = useLazyGetClinicPatientInfoQuery();

  //Actions
  const checkIsRowSelected = useCallback((id: string) => selectedRow === id, [selectedRow]);

  const resetSelection = useCallback(() => {
    setSelectedRow(null);
    dispatch(change(formName, PatientSearchField.selectedPhoneNumber, null));
    dispatch(change(formName, PatientSearchField.selectedGuardian, null));
    dispatch(change(formName, PatientSearchField.patientId, null));
    dispatch(resetPatient());
  }, [dispatch, formName, resetPatient]);

  const selectionChanged = useCallback(
    async (id: string) => {
      const selectedId = selectedRow === id ? null : id;
      setSelectedRow(selectedId);
      const selectedPatient = selectedId ? data?.data?.find((patient) => patient.id === selectedId) : undefined;
      if (selectedPatient) {
        // @ts-ignore
        dispatch(setPatient(selectedPatient));
        const defaultNumber =
          selectedPatient.phoneNumber.find((number) => number.details.isDefault) || selectedPatient.phoneNumber[0];
        dispatch(change(formName, PatientSearchField.patientId, selectedPatient.id));
        dispatch(change(formName, PatientSearchField.selectedGuardian, null));
        dispatch(change(formName, PatientSearchField.selectedPhoneNumber, defaultNumber));
      } else {
        dispatch(change(formName, PatientSearchField.patientId, null));
        dispatch(change(formName, PatientSearchField.selectedGuardian, null));
        dispatch(change(formName, PatientSearchField.selectedPhoneNumber, null));
        dispatch(resetPatient());
      }
    },
    [dispatch, selectedRow, data, formName, setPatient, resetPatient],
  );

  const handleSetParams = useCallback(
    (newParams?: QueryParams) => {
      setParams((prevState) => {
        const params = { ...prevState, ...newParams };
        if (clinicId) {
          resetSelection();
          triggerFetch({ clinicId, params });
        }
        return params;
      });
    },
    [clinicId, resetSelection, triggerFetch],
  );

  const handleFilterChange = useCallback(
    (values: Partial<PatientSearchFormValues>) => {
      const prevFilters = {
        [PatientSearchField.searchPhoneNumber]: params?.[PatientSearchField.searchPhoneNumber],
        [PatientSearchField.searchClinicyMrn]: params?.[PatientSearchField.searchClinicyMrn],
        [PatientSearchField.searchInternalFileNumber]: params?.[PatientSearchField.searchInternalFileNumber],
        [PatientSearchField.searchGovernmentIssuedId]: params?.[PatientSearchField.searchGovernmentIssuedId],
      };

      // TODO: [PT-8143] We need to standarize the validator for the form
      const isSearchPhoneNumberValid = values.searchPartialPhoneNumber
        ? !phoneNumber(values.searchPartialPhoneNumber)
        : true;
      const isSearchClinicyMrnValid = values.searchClinicyMrn
        ? !minLength(MIN_SEARCH_CLINICY_MRN_LENGTH)(values.searchClinicyMrn)
        : true;
      const isSearchInternalFileNumberValid = values.searchInternalFileNumber
        ? !minLength(MIN_SEARCH_INTERNAL_FILE_NUMBER_LENGTH)(values.searchInternalFileNumber)
        : true;
      const isSearchGovernmentIssuedIdValid = values.searchGovernmentIssuedId
        ? !minLength(MIN_SEARCH_GOVERNMENT_ID_LENGTH)(values.searchGovernmentIssuedId)
        : true;

      const isSearchSectionValid =
        isSearchPhoneNumberValid &&
        isSearchClinicyMrnValid &&
        isSearchInternalFileNumberValid &&
        isSearchGovernmentIssuedIdValid;

      if (!isEqual(prevFilters, values) && isSearchSectionValid) {
        handleSetParams(values);
      }
    },
    [handleSetParams, params],
  );

  return {
    data: data,
    isLoading,
    isFetching,
    params,
    checkIsRowSelected,
    isAnySelected,
    selectionChanged,
    resetSelection,
    handleFilterChange,
    handleSetParams,
  };
};
