import { useMemo } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { getTranslation, useAppIntl } from 'app/helpers';
import { AssessmentBillingItem } from 'app/assessment/billingSection/forms/types';

const bodyStyle = { fontSize: '9px', color: 'black' };
const headerStyle = { fontSize: '12px', color: 'black' };

export const useServiceItemsColumns = () => {
  const { formatMessage, locale } = useAppIntl();

  return useMemo(
    (): ColumnDescription<AssessmentBillingItem>[] => [
      {
        dataField: 'item',
        text: formatMessage({ id: 'TREATMENT-PLAN.TABLE.ITEM' }),
        formatter: (_, row) => row.billingItemName,
        style: bodyStyle,
        headerStyle,
      },
      {
        dataField: 'onInsurance',
        text: formatMessage({ id: 'CORE.LABEL.PAID-WITH-INSURANCE' }),
        formatter: (_, row) => formatMessage({ id: row.onInsurance ? 'CORE.BUTTON.YES' : 'CORE.BUTTON.NO' }),
        style: bodyStyle,
        headerStyle,
      },
      {
        dataField: 'quantity',
        text: formatMessage({ id: 'CORE.LABEL.QUANTITY' }),
        formatter: (_, row) => row.quantity,
        style: bodyStyle,
        headerStyle,
      },
      {
        dataField: 'status',
        text: formatMessage({ id: 'CORE.TABLE.STATUS' }),
        formatter: (_, row, i, { locale }) => getTranslation(row.status, locale),
        formatExtraData: { locale },
        style: bodyStyle,
        headerStyle,
      },
    ],
    [formatMessage, locale],
  );
};
