import { useToaster } from 'react-hot-toast';
import { useAppIntl } from 'app/helpers';
import { ErrorIcon, SuccessIcon } from 'app/components';
import './notifications.scss';
import { isValidElement } from 'react';

export const Notifications = () => {
  const { formatMessage } = useAppIntl();
  const { toasts, handlers } = useToaster({ duration: 4500 });
  const { startPause, endPause, calculateOffset, updateHeight } = handlers;

  return (
    <div className="notifications" onMouseEnter={startPause} onMouseLeave={endPause}>
      {toasts.map((toast) => {
        const isSuccessType = toast.type === 'success';
        const isErrorType = toast.type === 'error';

        const offset = calculateOffset(toast, {
          reverseOrder: false,
          gutter: 20,
        });

        const ref = (el: any) => {
          if (el && !toast.height) {
            const height = el.getBoundingClientRect().height;
            updateHeight(toast.id, height);
          }
        };

        return (
          <div
            key={toast.id}
            ref={ref}
            className="notification"
            style={{
              opacity: toast.visible ? 1 : 0,
              marginTop: `${offset}px`,
            }}
            {...toast.ariaProps}
          >
            {(isSuccessType || isErrorType) && (
              <div className="notification__icon me-3">
                {isSuccessType && <SuccessIcon />}
                {isErrorType && <ErrorIcon />}
              </div>
            )}
            <div className="notification__message">
              {typeof toast.message === 'string' && formatMessage({ id: toast.message, defaultMessage: toast.message })}
              {typeof toast.message !== 'string' && isValidElement(toast.message) && toast.message}
            </div>
          </div>
        );
      })}
    </div>
  );
};
