import round from 'lodash/round';
import { InvoiceDiscountType, InvoiceItem } from 'app/types';

type Item = Pick<InvoiceItem, 'discount' | 'price' | 'quantity' | 'vat'>;

export const getGrossPrice = (item: Item): number => round(item.price * item.quantity, 2);
export const getPriceAfterDiscount = (item: Item): number => {
  const price = getGrossPrice(item);
  const discountType = item.discount.discountTypeView.value;
  const discountValue = item.discount.value;

  if (discountType === InvoiceDiscountType.Percentage) {
    return round(price - (price * discountValue) / 100 + Number.EPSILON, 2);
  }

  if (discountType === InvoiceDiscountType.Amount) {
    return round(price - discountValue, 2);
  }

  return price;
};
export const getPercentValue = (value: number, percent: number) =>
  round(value - value * ((100 - percent) / 100) + Number.EPSILON, 2);
export const getVatValue = (item: Item): number =>
  round((getPriceAfterDiscount(item) / 100) * item.vat + Number.EPSILON, 2);
export const getTotalPrice = (item: Item): number => round(getPriceAfterDiscount(item) + getVatValue(item), 2);
export const getDiscountValue = (item: Item): number => round(getGrossPrice(item) - getPriceAfterDiscount(item), 2);
