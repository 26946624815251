import { PreferredLanguage } from 'app/shared';
import { Option } from './Option';
import { RemoteFile } from './RemoteFile';

export enum UserRoles {
  Doctor = '7',
  Manager = '5',
  ManagerAdmin = '2',
  MasterAdmin = '1',
  None = '0',
  Owner = '4',
  Reception = '6',
  ReceptionAdmin = '3',
  Viewer = '8',
  Nurse = '10',
}

export interface UserPermissions {
  allowedForAppointmentsActions: boolean;
  allowedForBilling: boolean;
  allowedForBookAppointments?: boolean;
  allowedForCreateCreditNote: boolean;
  allowedForCreateInvoice: boolean;
  allowedForCreateOrders: boolean;
  allowedForCreatePatientProfile: boolean;
  allowedForCreateSpecialityNotes?: boolean;
  allowedForDashboard: boolean;
  allowedForDeclarations: boolean;
  allowedForEMR: boolean;
  allowedForEditAppointments?: boolean;
  allowedForEditPatientProfile: boolean;
  allowedForEditPayment: boolean;
  allowedForExportAppointments: boolean;
  allowedForExportInsuranceClaim: boolean;
  allowedForInitialAssessment: boolean;
  allowedForInsuranceApproval: boolean;
  allowedForManageInsuranceApproval: boolean;
  allowedForInsuranceClaims: boolean;
  allowedForInsuranceManagement: boolean;
  allowedForInvoicing: boolean;
  allowedForManageInsuranceClaim: boolean;
  allowedForMassAdding: boolean;
  allowedForMergePatientProfile: boolean;
  allowedForNotEncryptedPhoneNumbers: boolean;
  allowedForTakeActionReferral: boolean;
  allowedForTreatmentView: boolean;
  allowedForUpdateOrders: boolean;
  allowedForUpdateSpecialityNotes?: boolean;
  allowedForViewBillingItems: boolean;
  allowedForViewInsuranceClaim: boolean;
  allowedForViewInsuranceContractsAndPolicies?: boolean;
  allowedForViewInvoice: boolean;
  allowedForViewOrders: boolean;
  allowedForViewPatientProfile: boolean;
  allowedForViewPatientSources: boolean;
  allowedForViewReferral: boolean;
  allowedForWriteBillingItems: boolean;
  allowedForWriteInsuranceContractsAndPolicies?: boolean;
  allowedForWritePatientSources: boolean;
  allowedForReferralApproval: boolean;
  allowedForManageReferralApproval: boolean;
}

export type User = {
  assignedToAllBranches?: boolean;
  assignedToAllDoctors?: boolean;
  branches: Option[];
  clinic?: Option;
  clinicyId?: string;
  clinicMode?: Option;
  email?: string;
  emailConfirmed?: boolean;
  hasToChangePassword?: boolean;
  id?: string;
  lastLogin?: string | null;
  photo?: RemoteFile | null;
  preferredNotificationLanguage?: Option<PreferredLanguage>;
  roles: Option<UserRoles>[];
  username?: string;
  filtersDefaults: {
    defaultBranch: Option | null;
    defaultSpeciality: Option | null;
  } | null;
} & Partial<UserPermissions>;

export interface AdminUser extends User {
  allClinics: boolean;
  clinics: Option[];
  firstName?: string;
  lastName?: string;
}

export interface ClinicUser extends User {
  assignedToAllDoctors: boolean;
  firstName?: string;
  lastName?: string;
}

export enum UserLogObjectType {
  Appointment = '1',
  BranchWorkingTime = '2',
  BusyTime = '3',
  ClinicBranch = '4',
  Clinic = '5',
  CustomWorkingTime = '6',
  Dependant = '7',
  Doctor = '8',
  DoctorSubSpeciality = '9',
  InsuranceCompany = '10',
  Patient = '11',
  Speciality = '12',
  SubSpeciality = '13',
  User = '14',
  WaitingAppointment = '15',
  WorkingTime = '16',
}

export type UserLog = {
  action: string;
  branch: Option;
  dateOfAction: string;
  objectId: string;
  objectName: string;
  objectType: UserLogObjectType;
};

export enum UserPermissionsFlag {
  AllSelected = 'allPermissionsSelected',
  AccountBalance = 'accountBalance',
  Actions = 'actions',
  BookAppointment = 'bookAppointment',
  Create = 'create',
  CreateAndEdit = 'createAndEdit',
  Dashboard = 'dashboard',
  Declarations = 'declarations',
  Edit = 'edit',
  EditPayment = 'editPayment',
  Export = 'export',
  InitialAssessment = 'initialAssessment',
  InsuranceApprovals = 'insuranceApprovals',
  ManageStatus = 'manageStatus',
  MassAdding = 'massAdding',
  Merge = 'merge',
  TreatmentView = 'treatmentView',
  View = 'view',
  Manage = 'manage',
}
