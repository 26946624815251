import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import {
  Branch,
  BranchDeclaration,
  BranchDeclarationLogsItem,
  BranchDeclarationStatus,
  BranchProduct,
  QueryParams,
  QueryResponse,
  QueryResponseWithPagination,
} from 'app/types';
import { DeclarationBody, ProductBody } from 'app/services/branches/types';

export const branchesApi = createApi({
  reducerPath: 'branchesApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['BranchDeclarations', 'Branches', 'BranchProducts', 'Branch'],
  endpoints: (builder) => ({
    getBranch: builder.query<QueryResponse<Branch>, string>({
      query: (branchId) => {
        return {
          url: `/branches/${branchId}`,
        };
      },
      providesTags: ['Branch'],
    }),
    getBranchDeclaration: builder.query<BranchDeclaration, string>({
      query: (declarationId) => {
        return {
          url: `/branch-declarations/${declarationId}`,
        };
      },
    }),
    getBranchDeclarations: builder.query<
      QueryResponseWithPagination<BranchDeclaration[]>,
      { branchId: string; params: QueryParams | undefined }
    >({
      query: ({ branchId, params }) => {
        return {
          url: `/branches/${branchId}/declarations/active`,
          params,
        };
      },
      providesTags: ['BranchDeclarations'],
    }),
    getBranchDeclarationLogs: builder.query<
      QueryResponseWithPagination<BranchDeclarationLogsItem[]>,
      { declarationId: string; params: QueryParams | undefined }
    >({
      query: ({ declarationId, params }) => {
        return {
          url: `/branch-declarations/${declarationId}/actions`,
          params,
        };
      },
    }),
    getDeletedBranchDeclarations: builder.query<
      QueryResponseWithPagination<BranchDeclaration[]>,
      { branchId: string; params: QueryParams | undefined }
    >({
      query: ({ branchId, params }) => {
        return {
          url: `/branches/${branchId}/declarations/deleted`,
          params,
        };
      },
      providesTags: ['BranchDeclarations'],
    }),
    createDeclaration: builder.mutation<void, { branchId: string; body: DeclarationBody }>({
      query: ({ branchId, body }) => ({
        url: `/branches/${branchId}/declarations`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['BranchDeclarations'],
    }),
    editDeclaration: builder.mutation<void, { declarationId: string; body: DeclarationBody }>({
      query: ({ declarationId, body }) => ({
        url: `/branch-declarations/${declarationId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['BranchDeclarations'],
    }),
    changeDeclarationStatus: builder.mutation<void, { declarationId: string; status: BranchDeclarationStatus }>({
      query: ({ declarationId, status }) => ({
        url: `/branch-declarations/${declarationId}/change-status/${status}`,
        method: 'PUT',
      }),
      invalidatesTags: ['BranchDeclarations'],
    }),

    getBranchesByClinic: builder.query<
      QueryResponseWithPagination<Branch[]>,
      { clinicId: string; params: QueryParams | undefined }
    >({
      query: ({ clinicId, params }) => ({
        url: `/clinics/${clinicId}/branches`,
        params,
      }),
      providesTags: ['Branches'],
    }),

    getDeletedBranchesByClinic: builder.query<
      QueryResponseWithPagination<Branch[]>,
      { clinicId: string; params: QueryParams | undefined }
    >({
      query: ({ clinicId, params }) => ({
        url: `/clinics/${clinicId}/deleted-branches`,
        params,
      }),
      providesTags: ['Branches'],
    }),

    getBranchProducts: builder.query<
      QueryResponseWithPagination<BranchProduct[]>,
      { branchId: string; params: QueryParams | undefined }
    >({
      query: ({ branchId, params }) => {
        return {
          url: `branches/${branchId}/products`,
          params,
        };
      },
      providesTags: ['BranchProducts'],
    }),

    addBranchProducts: builder.mutation<void, { body: ProductBody; branchId: string; params: QueryParams | undefined }>(
      {
        query: ({ body, params, branchId }) => ({
          url: `/branches/${branchId}/products`,
          method: 'POST',
          params,
          body,
        }),
        invalidatesTags: ['BranchProducts'],
      },
    ),

    editBranchProducts: builder.mutation<void, { body: ProductBody; branchId: string; serviceId: string }>({
      query: ({ body, branchId, serviceId }) => ({
        url: `/branches/${branchId}/products/${serviceId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['BranchProducts'],
    }),

    changeServiceStatus: builder.mutation<void, { branchId: string; serviceId: string; status: string }>({
      query: ({ branchId, serviceId, status }) => ({
        url: `/branches/${branchId}/products/${serviceId}/status`,
        method: 'PUT',
        params: {
          newStatus: status,
        },
      }),
      invalidatesTags: ['BranchProducts'],
    }),
  }),
});

export const {
  useChangeDeclarationStatusMutation,
  useCreateDeclarationMutation,
  useEditDeclarationMutation,
  useGetBranchDeclarationLogsQuery,
  useGetBranchDeclarationsQuery,
  useGetBranchQuery,
  useGetDeletedBranchDeclarationsQuery,
  useLazyGetBranchDeclarationQuery,
  useGetBranchesByClinicQuery,
  useGetDeletedBranchesByClinicQuery,
  useGetBranchProductsQuery,
  useAddBranchProductsMutation,
  useEditBranchProductsMutation,
  useChangeServiceStatusMutation,
} = branchesApi;
