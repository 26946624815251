import { useMemo } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { Input } from 'reactstrap';
import { ClinicPatientShortInfo } from 'app/services/patients/types';
import { dateOfBirthFormatter } from 'app/shared/patientSearch/patientSearchResults/helpers/dateOfBirthFormatter';
import { useAppIntl, getTranslation, getPatientFullName } from 'app/helpers';

interface Props {
  checkIsSelected: (id: string) => boolean;
}

export const useColumns = ({ checkIsSelected }: Props) => {
  const { formatMessage, locale } = useAppIntl();

  return useMemo(
    (): ColumnDescription<ClinicPatientShortInfo>[] => [
      {
        dataField: 'select',
        text: formatMessage({ id: 'CORE.BUTTON.SELECT' }),
        formatter: (_, row, rowIndex, { checkIsSelected }) => (
          <Input id={`patientRow.${rowIndex}`} type="checkbox" checked={checkIsSelected(row.id)} readOnly />
        ),
        formatExtraData: { checkIsSelected },
      },
      {
        dataField: 'clinicyMrn',
        text: formatMessage({ id: 'CORE.LABEL.CLINICY-MRN' }),
        classes: 'text-center',
        headerClasses: 'text-center',
      },
      {
        dataField: 'fullName',
        text: formatMessage({ id: 'CORE.TABLE.FULL-NAME' }),
        style: { minWidth: '160px' },
        formatter: (_, row, __, { locale }) => getPatientFullName(row, locale),
        formatExtraData: { locale },
      },
      {
        dataField: 'gender',
        formatter: (_, row) => getTranslation(row.gender, locale) || '-',
        text: formatMessage({ id: 'CORE.TABLE.GENDER' }),
      },
      {
        dataField: 'dateOfBirth',
        text: formatMessage({ id: 'CORE.LABEL.DATE-OF-BIRTH' }),
        formatter: dateOfBirthFormatter,
        formatExtraData: { formatMessage },
        classes: 'text-center',
        headerClasses: 'text-center',
      },
      {
        dataField: 'internalFileNumber',
        text: formatMessage({ id: 'CORE.LABEL.INTERNAL-FILE-NUMBER' }),
        formatter: (_, row) => row.internalFileNumber || '-',
      },
      {
        dataField: 'governmentId',
        text: formatMessage({ id: 'CORE.LABEL.GOVERNMENT-ID' }),
        formatter: (_, row) =>
          row.governmentIssuedIdType && row.governmentIssuedId ? (
            <>
              <div className="mb-1">{getTranslation(row.governmentIssuedIdType, locale)}</div>
              {row.governmentIssuedId}
            </>
          ) : (
            '-'
          ),
        classes: 'text-nowrap',
      },
      {
        dataField: 'patientType',
        text: formatMessage({ id: 'CORE.LABEL.PATIENT-TYPE' }),
        formatter: (_, row) => getTranslation(row.patientType, locale) || '-',
      },
    ],
    [formatMessage, locale, checkIsSelected],
  );
};
