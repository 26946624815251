import { useCallback } from 'react';
import { useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import { InvoicingProcessStep } from 'app/redux/invoicingProcess/types';
import { setStep } from 'app/redux/invoicingProcess/invoicingProcess.actions';
import { useInvoicingProcessState } from 'app/features/invoicingProcess/process/helpers';
import { PaymentModeForm } from 'app/features/invoicingProcess/process/paymentMode/form/form';
import { ReferralInfo } from 'app/features/invoicingProcess/process/paymentMode/referralInfo/referralInfo';
import { AccountBalance } from 'app/features/invoicingProcess/process/components/accountBalance/accountBalance';
import { SectionHeading } from 'app/features/invoicingProcess/process/components/sectionHeading/sectionHeading';
import { PreviousApproval } from 'app/features/invoicingProcess/process/paymentMode/previousApproval/previousApproval';
import { AvailablePackages } from 'app/features/invoicingProcess/process/paymentMode/availablePackages/availablePackages';

export const PaymentMode = () => {
  const dispatch = useAppDispatch();
  const { invoicePackageSnapshotId, requestId, isCreateCreditNoteMode } = useInvoicingProcessState();
  const { formatMessage } = useAppIntl();
  const invoicingProcess = useAppSelector((state) => state.invoicingProcess.data);
  const appointmentId = useAppSelector((state) => state.invoicingProcess.appointmentId); // We can not take appointmentId from invoicingProcess.data (value may change)
  const availablePackageQuantity = useAppSelector((state) => state.invoicingProcess.data?.availablePackageQuantity);
  const hasAvailablePackages = !!availablePackageQuantity && !invoicePackageSnapshotId && !requestId;

  const onSubmit = useCallback(() => {
    dispatch(setStep(InvoicingProcessStep.Items));
  }, [dispatch]);

  return (
    <>
      <SectionHeading title={formatMessage({ id: 'CORE.LABEL.PAYMENT-MODE', defaultMessage: 'Payment Mode' })} />
      {hasAvailablePackages && <AvailablePackages />}
      <AccountBalance className="mb-4" />
      <PaymentModeForm appointmentId={appointmentId} invoicingProcess={invoicingProcess} onSubmit={onSubmit} />
      {!isCreateCreditNoteMode && (
        <>
          <ReferralInfo />
          <PreviousApproval />
        </>
      )}
    </>
  );
};
