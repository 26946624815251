import {
  CREATE_INVOICE_REQUEST_SUCCESS,
  POST_INSURANCE_APPROVAL_SUCCESS,
  PUT_INSURANCE_APPROVAL_STATUS_SUCCESS,
  PUT_INSURANCE_APPROVAL_SUCCESS,
} from 'app/redux/takeAction/takeAction.types';
import { refreshInsuranceApprovalsList } from 'app/sagas/insurances/generators';
import { takeEvery } from 'redux-saga/effects';
import { SEND_REQUEST_FOR_APPROVAL_SUCCESS } from 'app/redux/doctorAssessment/doctorAssessment.types';

function* insurancesSaga() {
  yield takeEvery(
    [
      PUT_INSURANCE_APPROVAL_STATUS_SUCCESS,
      SEND_REQUEST_FOR_APPROVAL_SUCCESS,
      PUT_INSURANCE_APPROVAL_SUCCESS,
      POST_INSURANCE_APPROVAL_SUCCESS,
      CREATE_INVOICE_REQUEST_SUCCESS,
    ],
    refreshInsuranceApprovalsList,
  );
}

export default insurancesSaga;
