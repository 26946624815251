export const TOGGLE_MODAL = 'modals/TOGGLE_MODAL';
export const TOGGLE_CHANGE_PASSWORD_MODAL = 'modals/TOGGLE_CHANGE_PASSWORD_MODAL';
export const TOGGLE_ADD_BRANCH_MODAL = 'modals/TOGGLE_ADD_BRANCH_MODAL';
export const TOGGLE_EDIT_BRANCH_MODAL = 'modals/TOGGLE_EDIT_BRANCH_MODAL';
export const TOGGLE_EDIT_BRANCH_REQUEST_MODAL = 'modals/TOGGLE_EDIT_BRANCH_REQUEST_MODAL';
export const TOGGLE_EDIT_CLINIC_MODAL = 'modals/TOGGLE_EDIT_CLINIC_MODAL';
export const TOGGLE_EDIT_CLINIC_REQUEST_MODAL = 'modals/TOGGLE_EDIT_CLINIC_REQUEST_MODAL';
export const TOGGLE_EDIT_SYSTEMOWNER_MODAL = 'modals/TOGGLE_EDIT_SYSTEMOWNER_MODAL';
export const TOGGLE_ADD_DOCTOR_MODAL = 'modals/TOGGLE_ADD_DOCTOR_MODAL';
export const TOGGLE_ADD_DOCTOR_BUSY_TIME_MODAL = 'modals/TOGGLE_ADD_DOCTOR_BUSY_TIME_MODAL';
export const TOGGLE_BRANCH_IMAGES_MODAL = 'modals/TOGGLE_BRANCH_IMAGES_MODAL';
export const TOGGLE_ADD_MEDICAL_SERVICE_MODAL = 'modals/TOGGLE_ADD_MEDICAL_SERVICE_MODAL';
export const TOGGLE_EDIT_MEDICAL_SPECIALITY_MODAL = 'modals/TOGGLE_EDIT_MEDICAL_SPECIALITY_MODAL';
export const TOGGLE_ADD_BRANCH_WORKING_HOURS_MODAL = 'modals/TOGGLE_ADD_BRANCH_WORKING_HOURS_MODAL';
export const TOGGLE_EDIT_PATIENT_MODAL = 'modals/TOGGLE_EDIT_PATIENT_MODAL';
export const TOGGLE_EDIT_PATIENT_NUMBER_MODAL = 'modals/TOGGLE_EDIT_PATIENT_NUMBER_MODAL';
export const TOGGLE_ADD_PATIENT_MODAL = 'modals/TOGGLE_ADD_PATIENT_MODAL';
export const TOGGLE_BLOCK_PATIENT_MODAL = 'modals/TOGGLE_BLOCK_PATIENT_MODAL';
export const TOGGLE_EDIT_PROFILE_MODAL = 'modals/TOGGLE_EDIT_PROFILE_MODAL';
export const TOGGLE_EXPORT_TO_XLS_MODAL = 'modals/TOGGLE_EXPORT_TO_XLS_MODAL';
export const TOGGLE_DELETED_DOCTOR_MODAL = 'modals/TOGGLE_DELETED_DOCTOR_MODAL';
export const TOGGLE_REJECTED_CLINIC_INFO = 'modals/TOGGLE_REJECTED_CLINIC_INFO';
export const TOGGLE_ADD_USER_ROLE_MODAL = 'modals/TOGGLE_ADD_USER_ROLE_MODAL';
export const TOGGLE_EDIT_USER_ROLE_MODAL = 'modals/TOGGLE_EDIT_USER_ROLE_MODAL';
export const TOGGLE_CANCEL_PENDING_CLINIC = 'modals/TOGGLE_CANCEL_PENDING_CLINIC';
export const TOGGLE_REJECT_PENDING_CLINIC = 'modals/TOGGLE_REJECT_PENDING_CLINIC';
export const TOGGLE_PATIENT_MOBILE_NUMBER_EXISTS = 'modals/TOGGLE_PATIENT_MOBILE_NUMBER_EXISTS';
export const TOGGLE_CONFIRMATION_MODAL = 'modals/TOGGLE_CONFIRMATION_MODAL';
export const TOGGLE_ADD_PRODUCT_MODAL = 'modals/TOGGLE_ADD_PRODUCT_MODAL';
export const TOGGLE_ADD_PRODUCT = 'modals/TOGGLE_ADD_PRODUCT';
export const TOGGLE_BRANCHES_MODAL = 'modals/TOGGLE_BRANCHES_MODAL';
export const TOGGLE_EDIT_PRODUCT_MODAL = 'modals/TOGGLE_EDIT_PRODUCT_MODAL';
export const TOGGLE_DELETED_BRANCH_MODAL = 'modals/TOGGLE_DELETED_BRANCH_MODAL';
export const TOGGLE_UNBLOCK_PATIENT_MODAL = 'modals/TOGGLE_UNBLOCK_PATIENT_MODAL';
export const TOGGLE_REFERENCE_NUMBER_MODAL = 'modals/TOGGLE_REFERENCE_NUMBER_MODAL';
export const TOGGLE_DOCTOR_WORKING_HOURS_MODAL = 'modals/TOGGLE_DOCTOR_WORKING_HOURS_MODAL';
export const TOGGLE_EDIT_STATUS_MODAL = 'modals/TOGGLE_EDIT_STATUS_MODAL';
export const TOGGLE_ADD_DRAFT_ITEM_MODAL = 'modals/TOGGLE_ADD_DRAFT_ITEM_MODAL';
export const TOGGLE_EDIT_DRAFT_ITEM_MODAL = 'modals/TOGGLE_EDIT_DRAFT_ITEM_MODAL';
export const TOGGLE_ADD_INVOICE_ITEM_MODAL = 'modals/TOGGLE_ADD_INVOICE_ITEM_MODAL';
export const TOGGLE_EDIT_INVOICE_ITEM_MODAL = 'modals/TOGGLE_EDIT_INVOICE_ITEM_MODAL';
export const TOGGLE_ADD_PATIENT_TO_CLINIC_MODAL = 'modals/TOGGLE_ADD_PATIENT_TO_CLINIC_MODAL';
