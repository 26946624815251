import { useCallback, useState } from 'react';
import { ClinicPatientShortInfo } from 'app/services/patients/types';

interface Props {
  selectionChanged: (patient: ClinicPatientShortInfo | null) => void;
  data?: ClinicPatientShortInfo[];
}

export const useSelectionHandlers = ({ data, selectionChanged }: Props) => {
  const [selectedRow, setSelectedRow] = useState<string | null>(null);

  const checkIsRowSelected = useCallback((id: string) => selectedRow === id, [selectedRow]);

  const resetSelection = useCallback(() => {
    setSelectedRow(null);
    selectionChanged(null);
  }, [selectionChanged]);

  const handleSelectionChange = useCallback(
    (id: string) => {
      const selectedId = selectedRow === id ? null : id;
      setSelectedRow(selectedId);
      const selectedPatient = selectedId ? data?.find((patient) => patient.id === selectedId) : undefined;
      if (selectedPatient) {
        selectionChanged(selectedPatient);
      } else {
        selectionChanged(null);
      }
    },
    [data, selectedRow, selectionChanged],
  );

  return {
    checkIsRowSelected,
    handleSelectionChange,
    resetSelection,
  };
};
