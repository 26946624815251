import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormText } from 'reactstrap';
import { Editor, EditorProps, EditorState } from 'react-draft-wysiwyg';
import { DraftHandleValue } from 'draft-js';
import classnames from 'classnames';
import { useAppIntl } from 'app/helpers';
import styles from 'app/components/textEditor/textEditor.module.scss';

const MAX_LENGTH = 5000;

export const TextEditor = (props: EditorProps) => {
  const { isRtl } = useAppIntl();

  // Local State
  const [showError, setShowError] = useState<boolean>(false);

  // Helper Variables
  const textAlignment: string = isRtl ? 'right' : 'left';
  const defaultToolbar = useMemo(
    (): object => ({
      options: ['inline', 'textAlign'],
      inline: {
        className: styles.toolbarButtons,
        options: ['bold', 'italic', 'underline', 'strikethrough'],
      },
      textAlign: {
        className: styles.toolbarButtons,
      },
    }),
    [],
  );

  // Handlers
  const handleBeforeInput = (typedText: string, state: EditorState): DraftHandleValue => {
    const prevTextLength = state.getCurrentContent().getPlainText().length;
    const totalTextLength = prevTextLength + typedText.length;

    // When typed text will not be added
    if (totalTextLength > MAX_LENGTH) {
      setShowError(true);
      return 'handled';
    } else {
      setShowError(false);
      return 'not-handled';
    }
  };

  const handlePastText = (pastingText: string, html: string, state: EditorState) => {
    const predictedTextLength = state.getCurrentContent().getPlainText().length + pastingText.length;

    // Block paste when pasting text above limit
    const isPasteBlocked = predictedTextLength > MAX_LENGTH;

    // Show error message on paste action failed
    setShowError(isPasteBlocked);

    return isPasteBlocked;
  };

  const onEditorStateChange = (state: EditorState) => {
    const prevTextLength = state.getCurrentContent().getPlainText().length;

    // When error message should be display
    const isMaxLength = prevTextLength >= MAX_LENGTH;

    setShowError(isMaxLength);
  };

  return (
    <>
      <Editor
        onEditorStateChange={onEditorStateChange}
        wrapperClassName={classnames(
          props.readOnly ? styles.disabledEditorWrapper : styles.editorWrapper,
          props.wrapperClassName,
        )}
        editorClassName={classnames(styles.textEditor, props.editorClassName)}
        toolbarClassName={classnames(styles.editorToolbar, props.toolbarClassName)}
        textAlignment={textAlignment}
        toolbar={props.toolbar ? props.toolbar : defaultToolbar}
        stripPastedStyles
        // @ts-ignore handleBeforeInput was removed of the types, which comes from react-draft-wysiwyg library
        handleBeforeInput={handleBeforeInput}
        handlePastedText={handlePastText}
        {...props}
      />
      {showError && (
        <FormText>
          <FormattedMessage id="CORE.TEXT.MAX-LENGTH-MESSAGE" values={{ value: MAX_LENGTH }} />
        </FormText>
      )}
    </>
  );
};
