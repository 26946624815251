import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import { BaseFieldProps, Field, change, FormSection, getFormValues } from 'redux-form';
import classnames from 'classnames';
import { PermissionItem, PermissionItemFlag, UserPermissionsFlag } from 'app/types';
import { useAppDispatch, useAppSelector } from 'app/helpers';
import {
  PermissionSwitch,
  PermissionSwitchProps,
} from 'app/shared/form-elements/permissions/components/permissionSwitch/permissionSwitch';
import styles from 'app/shared/form-elements/permissions/permissionItem.module.scss';

export interface PermissionsProps {
  formName: string;
  permissionItems: PermissionItem[];
  className?: string;
}

export const Permissions = ({ formName, permissionItems, className }: PermissionsProps) => {
  const dispatch = useAppDispatch();
  const formValues = useAppSelector((state) => getFormValues(formName)(state)) as any;
  const filteredPermissionItems = useMemo(
    () =>
      permissionItems
        .filter((permissionItem) => !permissionItem.hidden)
        .map((permissionItem) => ({
          ...permissionItem,
          flags: permissionItem.flags.filter((flag) => !flag.hidden),
        })),
    [permissionItems],
  );

  // Local State
  const [selectedId, setSelectedId] = useState<string>('');

  useEffect(() => {
    let allSelectState = true;
    filteredPermissionItems.forEach(({ scopeName, flags }) => {
      flags.forEach(({ name }) => {
        const fieldValue = formValues?.permissions?.[scopeName]?.[name];
        if (!fieldValue) {
          allSelectState = false;
        }
      });
    });
    const currentAllState = formValues?.[UserPermissionsFlag.AllSelected];
    if (currentAllState !== allSelectState) {
      dispatch(change(formName, `${UserPermissionsFlag.AllSelected}`, allSelectState));
    }
  }, [filteredPermissionItems, dispatch, formName, formValues?.permissions, formValues]);

  // Handlers
  const toggle = useCallback(
    (id: string) => {
      if (selectedId === id) {
        setSelectedId('');
      } else {
        setSelectedId(id);
      }
    },
    [selectedId],
  );

  const handleChangeFlag = useCallback(
    (newValue: boolean, name: string | undefined, flags: PermissionItemFlag[], flag: PermissionItemFlag) => {
      if (name) {
        // [i]: Example: permissions.appointments.edit -> permissions.appointments
        const sectionName = name.substring(0, name.lastIndexOf('.'));

        if (newValue) {
          // [i]: Switching ON - enable all required dependencies
          if (flag && flag.requires) {
            flag.requires.forEach((requiredFlagValue) => {
              dispatch(change(formName, `${sectionName}.${requiredFlagValue}`, true));
            });
          }
        } else {
          // [i]: Switching OFF - disable flags which require this one
          flags.forEach((otherFlag) => {
            if (otherFlag.requires?.includes(flag.name)) {
              dispatch(change(formName, `${sectionName}.${otherFlag.name}`, false));
            }
          });
        }

        // [i]: Update current flag state
        dispatch(change(formName, `${sectionName}.${flag.name}`, newValue));
      }
    },
    [dispatch, formName],
  );

  return useMemo(
    () => (
      <>
        <Accordion open={selectedId} toggle={toggle} className={className}>
          {filteredPermissionItems.map(({ scopeName, label, flags }, index) => (
            <FormSection name={scopeName} key={index}>
              <AccordionItem>
                <AccordionHeader targetId={`${index + 1}`} className={styles.accordionHeader}>
                  {label}
                </AccordionHeader>
                <AccordionBody accordionId={`${index + 1}`} className={styles.accordionBody}>
                  {flags.map((flag, index) => (
                    <div
                      key={index}
                      className={classnames({
                        'border-bottom': index + 1 < flags.filter((flag) => !flag.hidden).length,
                      })}
                    >
                      <Field<BaseFieldProps<PermissionSwitchProps>>
                        name={flag.name}
                        component={PermissionSwitch}
                        onChange={(_, newValue, __, name) => handleChangeFlag(newValue, name, flags, flag)}
                        props={{
                          label: flag.label,
                        }}
                      />
                    </div>
                  ))}
                </AccordionBody>
              </AccordionItem>
            </FormSection>
          ))}
        </Accordion>
      </>
    ),
    [selectedId, toggle, className, filteredPermissionItems, handleChangeFlag],
  );
};
