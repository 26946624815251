import React, { useEffect, useMemo } from 'react';
import { Col } from 'reactstrap';
import draftToHtml from 'draftjs-to-html';
import { useAppDispatch, useAppIntl, useAppSelector } from 'app/helpers';
import {
  FORMS_ASSESSMENT_BILLING,
  FORMS_ASSESSMENT_MEDICAL_PROFILE_FORM,
  FORMS_ASSESSMENT_PRESCRIPTIONS,
  FORMS_ASSESSMENT_PRINT_MODAL_FORM,
  FORMS_ASSESSMENT_SIGNATURE_FORM,
  LocalTable,
} from 'app/shared';
import {
  InitialAssessmentVitalsItems,
  useIcdCodesColumns,
  useLocationsColumns,
  usePastMedicalHistory,
  useServiceItemsColumns,
  useSurgicalHistory,
} from 'app/assessment/medicalProfileSection/components/helpers/index';
import { AssessmentBillingItem } from 'app/assessment/billingSection/forms/types';
import { useCheckViewStatus } from 'app/assessment/helpers';
import { CustomDescriptionList } from 'app/assessment/medicalProfileSection/components/customDescriptionList';
import { PrintModalFormData } from 'app/assessment/medicalProfileSection/components/modals/printModal/printModalBody';
import { MedicalProfileFormData } from 'app/assessment/medicalProfileSection/components/practitionerAssessment/practitionerAssessment';
import { BillingFormData } from 'app/assessment/billingSection/forms/billingForm/billingForm';
import { WrappedPrescriptionFormData, WrappedPrescriptionsFormData } from 'app/assessment/prescriptionSection/types';
import { PrescriptionPrintBody } from 'app/assessment/prescriptionSection/components/prescriptionPrintTemplate/prescriptionPrintBody';
import { PrescriptionPrintHeader } from 'app/assessment/prescriptionSection/components/prescriptionPrintTemplate/prescriptionPrintHeader';
import { usePrescriptionData } from 'app/assessment/prescriptionSection/components/prescriptionPrintTemplate/helpers/usePrescriptionData';
import { SignaturePrintField } from 'app/assessment/components/signaturePrintField/signaturePrintField';
import { fetchClinicLogo } from 'app/redux/doctorAssessment/doctorAssessment.actions';
import styles from './printPractitionerAssessment.module.scss';

export const PrintAssessment = () => {
  const { formatMessage } = useAppIntl();
  const { isRtl } = useAppIntl();
  const printHeaderData = usePrescriptionData();
  const dispatch = useAppDispatch();

  // Selectors
  const values: Partial<MedicalProfileFormData> | undefined = useAppSelector(
    (state) => state.form[FORMS_ASSESSMENT_MEDICAL_PROFILE_FORM]?.values,
  );
  const prescriptionsValues: WrappedPrescriptionsFormData | undefined = useAppSelector(
    (state) => state.form[FORMS_ASSESSMENT_PRESCRIPTIONS]?.values,
  );
  const servicesItemsValues: BillingFormData | undefined = useAppSelector(
    (state) => state.form[FORMS_ASSESSMENT_BILLING]?.values,
  );
  const billingNote = useAppSelector((state) => state.form[FORMS_ASSESSMENT_BILLING]?.values?.billNote);
  const signatureUrl = useAppSelector(
    (state) => state.form[FORMS_ASSESSMENT_SIGNATURE_FORM]?.values?.signatureFile.url,
  );
  const doctorName = useAppSelector(
    (state) => state.treatment.generalTreatmentInformation.data?.appointmentInformation.doctorName,
  );
  const printValues: Partial<PrintModalFormData> | undefined = useAppSelector(
    (state) => state.form[FORMS_ASSESSMENT_PRINT_MODAL_FORM]?.values,
  );

  const billingHistoricalData = useAppSelector((state) =>
    state.doctorAssessment.data?.previousBillingSections?.flatMap(
      (previousBillingItem) => previousBillingItem.assessmentBillingItems,
    ),
  );
  const prescriptionsHistoricalData = useAppSelector((state) =>
    state.doctorAssessment.data?.previousAssessmentPrescriptionSections?.flatMap(
      (historicalData) => historicalData.assessmentPrescriptions,
    ),
  );

  const assessmentData = useAppSelector((state) => state.doctorAssessment.data);
  const pastMedicalHistoryTable = printValues?.pastMedicalHistoryTable?.filter((row) => row.toPrint);
  const surgicalHistoryTable = printValues?.surgicalHistoryTable?.filter((row) => row.toPrint);

  // Helper Hooks
  const { isDraftMode } = useCheckViewStatus(assessmentData?.viewStatus, assessmentData?.completedAt);
  const columns = useIcdCodesColumns(true);
  const locationsColumns = useLocationsColumns();
  const serviceItemsColumns = useServiceItemsColumns();
  const pastMedicalHistoryColumns = usePastMedicalHistory();
  const surgicalHistoryColumns = useSurgicalHistory();

  //Local Table Data
  const billingItemsData = useMemo<AssessmentBillingItem[]>(() => {
    const hasAssessmentBillingItems = !!servicesItemsValues?.assessmentBillingItems?.length;
    const shouldIncludeHistoricalData = isDraftMode && billingHistoricalData && hasAssessmentBillingItems;
    const shouldIncludeAssessmentBillingItems = !isDraftMode || hasAssessmentBillingItems;

    if (shouldIncludeHistoricalData) {
      return [...billingHistoricalData, ...(servicesItemsValues?.assessmentBillingItems || [])];
    } else if (shouldIncludeAssessmentBillingItems) {
      return servicesItemsValues?.assessmentBillingItems || [];
    }
    return [];
  }, [billingHistoricalData, servicesItemsValues, isDraftMode]);

  const prescriptionsData = useMemo<WrappedPrescriptionFormData[]>(() => {
    const hasAssessmentPrescriptions = !!prescriptionsValues?.assessmentPrescriptions?.length;
    const shouldIncludeHistoricalData = isDraftMode && prescriptionsHistoricalData && hasAssessmentPrescriptions;
    const shouldIncludeAssessmentPrescriptions = !isDraftMode || hasAssessmentPrescriptions;

    if (shouldIncludeHistoricalData) {
      const historicalPrescriptions: WrappedPrescriptionFormData[] = prescriptionsHistoricalData
        .filter(Boolean)
        .map((p) => ({ prescriptionId: p.prescriptionId, assessmentPrescriptionDrugs: p.prescriptionDrugs }));
      const assessmentPrescriptions = prescriptionsValues?.assessmentPrescriptions?.filter(Boolean) || [];
      return [...historicalPrescriptions, ...assessmentPrescriptions];
    } else if (shouldIncludeAssessmentPrescriptions) {
      return prescriptionsValues?.assessmentPrescriptions?.filter(Boolean) || [];
    } else {
      return [];
    }
  }, [prescriptionsHistoricalData, prescriptionsValues, isDraftMode]);

  //Flags
  const showHealthEducation = useMemo(
    () => !!values?.healthEducation?.blocks?.[0]?.text && printValues?.healthEducation,
    [printValues, values],
  );
  const showPlan = useMemo(
    () => values?.procedureLocation && values?.procedureLocation.notes.length > 0 && printValues?.plan,
    [printValues, values],
  );
  const showGoal = useMemo(
    () => values?.goalLocation && values?.goalLocation.notes.length > 0 && printValues?.goal,
    [printValues, values],
  );

  useEffect(() => {
    if (printHeaderData?.clinicId) {
      dispatch(fetchClinicLogo(printHeaderData?.clinicId));
    }
  }, [dispatch, printHeaderData?.clinicId]);

  return (
    <>
      <div dir={isRtl ? 'rtl' : 'ltr'} className="mt-4">
        {values?.chiefComplaint?.blocks?.[0]?.text && values.chiefComplaint.blocks?.[0].text.trim().length > 0 && (
          <CustomDescriptionList
            label={formatMessage({ id: 'TREATMENT-PLAN.LABEL.CHIEF-COMPLAINT' })}
            value={draftToHtml(values.chiefComplaint)}
            className={`${styles.pageBreak} mb-4`}
          />
        )}
        {values?.presentIllnessHistory?.blocks?.[0]?.text &&
          values.presentIllnessHistory.blocks?.[0].text.trim().length > 0 && (
            <CustomDescriptionList
              label={formatMessage({ id: 'TREATMENT-PLAN.LABEL.HISTORY-OF-PRESENT-ILLNESS' })}
              value={draftToHtml(values.presentIllnessHistory)}
              className={`${styles.pageBreak} mb-4`}
            />
          )}
        {values?.medicalCondition?.blocks?.[0]?.text && values.medicalCondition.blocks?.[0].text.trim().length > 0 && (
          <CustomDescriptionList
            label={formatMessage({ id: 'TREATMENT-PLAN.LABEL.MEDICAL-CONDITION' })}
            value={draftToHtml(values.medicalCondition)}
            className={`${styles.pageBreak} mb-4`}
          />
        )}
        {!!pastMedicalHistoryTable?.length && printValues?.pastMedicalHistory && (
          <Col className="mb-2">
            <div className={styles.pageBreak}>
              <h4>{formatMessage({ id: 'TREATMENT-PLAN.LABEL.PAST-MEDICAL-HISTORY' })}</h4>
              <LocalTable
                columns={pastMedicalHistoryColumns}
                data={pastMedicalHistoryTable}
                id="pastMedicalHistory"
                keyField="patientMedicalIllnessId"
                loading={false}
                classes={`table-sm mb-4`}
                wrapperClasses={styles.overflowHidden}
              />
            </div>
          </Col>
        )}
        {!!surgicalHistoryTable?.length && printValues?.surgicalHistory && (
          <Col className="mb-2">
            <div className={styles.pageBreak}>
              <h4>{formatMessage({ id: 'TREATMENT-PLAN.LABEL.SURGICAL-HISTORY' })}</h4>
              <LocalTable
                columns={surgicalHistoryColumns}
                data={surgicalHistoryTable}
                id="surgicalHistory"
                keyField="patientSurgeryId"
                loading={false}
                classes={`table-sm mb-4`}
                wrapperClasses={styles.overflowHidden}
              />
            </div>
          </Col>
        )}
        {values?.systemReview?.blocks?.[0]?.text && values.systemReview.blocks?.[0].text.trim().length > 0 && (
          <CustomDescriptionList
            label={formatMessage({ id: 'TREATMENT-PLAN.LABEL.REVIEW-OF-SYSTEM' })}
            value={draftToHtml(values.systemReview)}
            className={`${styles.pageBreak} mb-4`}
          />
        )}
        {values?.clinicalExamination?.blocks?.[0]?.text &&
          values.clinicalExamination.blocks?.[0].text.trim().length > 0 && (
            <CustomDescriptionList
              label={formatMessage({ id: 'TREATMENT-PLAN.LABEL.CLINICAL-EXAMINATION' })}
              value={draftToHtml(values.clinicalExamination)}
              className={`${styles.pageBreak} mb-4`}
            />
          )}
        {printValues?.vitals && (
          <div className={styles.pageBreak}>
            <InitialAssessmentVitalsItems />
          </div>
        )}
        {/*TODO: LAB REPORT HERE IN THE FUTURE*/}
        {values?.diagnosis?.blocks?.[0]?.text && values.diagnosis.blocks?.[0].text.trim().length > 0 && (
          <div className={`${styles.pageBreak} mb-4`}>
            {values?.diagnosis && (
              <CustomDescriptionList
                label={formatMessage({ id: 'TREATMENT-PLAN.LABEL.DIAGNOSIS' })}
                value={draftToHtml(values.diagnosis)}
              />
            )}
            {values?.icdTable && values?.icdTable?.length > 0 && (
              <LocalTable
                columns={columns}
                data={values?.icdTable}
                id="icd"
                keyField="icdCode"
                loading={false}
                classes={`table-sm mb-2 `}
                wrapperClasses={styles.overflowHidden}
              />
            )}
          </div>
        )}
        {values?.assessment?.blocks?.[0]?.text && values.assessment.blocks?.[0].text.trim().length > 0 && (
          <CustomDescriptionList
            label={formatMessage({ id: 'TREATMENT-PLAN.TEXT.ASSESSMENT' })}
            value={draftToHtml(values.assessment)}
            className={`${styles.pageBreak} mb-4`}
          />
        )}

        {(!!billingItemsData.length || billingNote) && (
          <Col className="mb-5" style={{ overflowY: 'hidden' }}>
            <div className={styles.pageBreak}>
              {!!billingItemsData.length && (
                <>
                  <h4>{formatMessage({ id: 'TREATMENT-PLAN.LABEL.PROCEDURES-SERVICES' })}</h4>
                  <LocalTable
                    columns={serviceItemsColumns}
                    data={billingItemsData}
                    id="servicesItems"
                    keyField="item"
                    loading={false}
                    classes={`table-sm mb-2`}
                    wrapperClasses={styles.overflowHidden}
                  />
                </>
              )}
              {billingNote && (
                <CustomDescriptionList
                  label={formatMessage({ id: 'TREATMENT-PLAN.TEXT.BILLING-NOTE' })}
                  value={billingNote}
                  className="mb-4"
                />
              )}
            </div>
          </Col>
        )}
        {showPlan && values?.procedureLocation?.notes && (
          <div className={styles.pageBreak}>
            <h4>{formatMessage({ id: 'TREATMENT-PLAN.LABEL.PLAN-SHORT-TERM' })}</h4>
            <LocalTable
              columns={locationsColumns}
              data={values?.procedureLocation?.notes}
              id="locations"
              keyField="number"
              loading={false}
              classes={`table-sm mb-4`}
              wrapperClasses={styles.overflowHidden}
            />
            {values?.plan?.blocks?.[0]?.text && values.plan.blocks?.[0].text.trim().length > 0 && (
              <CustomDescriptionList
                label={formatMessage({ id: 'TREATMENT-PLAN.LABEL.ADDITIONAL-PLAN-COMMENT' })}
                value={draftToHtml(values.plan)}
                className={`${styles.pageBreak} mb-4`}
              />
            )}
          </div>
        )}
        {showGoal && values?.goalLocation?.notes && (
          <div className={styles.pageBreak}>
            <h4>{formatMessage({ id: 'TREATMENT-PLAN.LABEL.GOAL' })}</h4>
            <LocalTable
              columns={locationsColumns}
              data={values?.goalLocation?.notes}
              id="locations"
              keyField="number"
              loading={false}
              classes={`table-sm mb-4`}
              wrapperClasses={styles.overflowHidden}
            />
            {values?.goal?.blocks?.[0]?.text && values.goal.blocks?.[0]?.text.trim().length > 0 && (
              <CustomDescriptionList
                label={formatMessage({ id: 'TREATMENT-PLAN.LABEL.ADDITIONAL-GOAL-COMMENT' })}
                value={draftToHtml(values.goal)}
                className={`${styles.pageBreak} mb-4`}
              />
            )}
          </div>
        )}
        {showHealthEducation &&
          values?.healthEducation?.blocks?.[0]?.text &&
          values.healthEducation.blocks?.[0].text.trim().length > 0 && (
            <CustomDescriptionList
              label={formatMessage({ id: 'TREATMENT-PLAN.LABEL.HEALTH-EDUCATION' })}
              value={draftToHtml(values?.healthEducation)}
              className={`${styles.pageBreak} mb-4`}
            />
          )}
        {values?.procedureUpdate?.blocks?.[0]?.text && values.procedureUpdate.blocks?.[0].text.trim().length > 0 && (
          <CustomDescriptionList
            label={formatMessage({ id: 'TREATMENT-PLAN.LABEL.PROCEDURE-UPDATE' })}
            value={draftToHtml(values.procedureUpdate)}
            className={`${styles.pageBreak} mb-4`}
          />
        )}
      </div>
      <div>
        {!!prescriptionsData.length && printHeaderData && (
          <div className={styles.separatePage}>
            {prescriptionsData.map((prescription, index) => {
              return (
                <div key={index}>
                  <PrescriptionPrintHeader data={printHeaderData} />
                  <PrescriptionPrintBody
                    prescriptionData={prescription}
                    doctorName={doctorName}
                    signatureUrl={signatureUrl}
                    showSignature={false}
                  />
                </div>
              );
            })}
          </div>
        )}
        <SignaturePrintField doctorName={doctorName} signatureUrl={signatureUrl} />
      </div>
    </>
  );
};
