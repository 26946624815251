import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from 'app/helpers';
import { InsuranceCompany, QueryParams, QueryResponse, QueryResponseWithPagination } from 'app/types';
import { InsuranceCompanyBodyParams } from 'app/services/insuranceCompanies/types';

export const insuranceCompaniesApi = createApi({
  reducerPath: 'insuranceCompaniesApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['insuranceCompanies', 'insuranceCompany'],
  endpoints: (builder) => ({
    // [EP-1]
    getInsuranceCompanies: builder.query<QueryResponseWithPagination<InsuranceCompany[]>, QueryParams | undefined>({
      query: (params) => ({
        url: '/insurance-companies',
        method: 'GET',
        params,
      }),
      providesTags: ['insuranceCompanies'],
    }),

    // [EP-2]
    getInsuranceCompany: builder.query<QueryResponse<InsuranceCompany>, string>({
      query: (insuranceCompanyId) => ({
        url: `/insurance-companies/${insuranceCompanyId}`,
        method: 'GET',
      }),
      providesTags: ['insuranceCompany'],
    }),

    // [EP-3]
    addInsuranceCompany: builder.mutation<void, InsuranceCompanyBodyParams>({
      query: (body) => ({
        url: '/insurance-companies',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['insuranceCompanies'],
    }),

    // [EP-4]
    editInsuranceCompany: builder.mutation<void, { insuranceCompanyId: string; body: InsuranceCompanyBodyParams }>({
      query: ({ insuranceCompanyId, body }) => ({
        url: `/insurance-companies/${insuranceCompanyId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['insuranceCompanies', 'insuranceCompany'],
    }),
  }),
});

export const {
  useGetInsuranceCompaniesQuery, // [EP-1]
  useGetInsuranceCompanyQuery, // [EP-2]
  useAddInsuranceCompanyMutation, // [EP-3]
  useEditInsuranceCompanyMutation, // [EP-4]
} = insuranceCompaniesApi;
